<template>
    <div class="preview_area show_sidebar active">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li :class="tab === 'profile' ? 'active' : ''" @click="tab = 'profile'">Profile</li>
                <li :class="tab === 'security' ? 'active' : ''" @click="tab = 'security'">Security </li>
                <li :class="tab === 'plans' ? 'active' : ''" @click="tab = 'plans'">Subscription</li>
                <li :class="tab === 'payment' ? 'active' : ''" @click="tab = 'payment'">Payment Method</li>
                <li :class="tab === 'billing' ? 'active' : ''" @click="tab = 'billing'">Billing History</li>
                <!-- <li :class="tab === 'services' ? 'active' : ''" @click="tab = 'services'">Additional Services</li>
                <li :class="tab === 'market' ? 'active' : ''" @click="tab = 'market'">Marketplace</li> -->
            </ul>
            <div class="tabs_content">
                <div class="header-main-sec notification-bar" v-if ="hasBillingNotificationBar">
                    <div class="content">
                        Your trial expires in {{ billingNotification.trial_remaining_days }} days. Set up your &nbsp; <router-link :to="{ name: 'AdminSettings' }">account here</router-link>.

                        <button class="action-button" @click="togglePaidSubscription()">Start Billing Early!</button>
                    </div>
                    <button class="close-button pointer"  @click ="billingNotificationClose">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
                <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <billing-profile v-if="tab === 'profile'" />
                <security-component v-if="tab === 'security'" />
                <subscription-component v-if="tab === 'plans'" />
                <payment-method v-if="tab === 'payment'" />
                <billing-history v-if="tab === 'billing'" />
                <additional-service v-if="tab === 'services'" />
                <marketplace-component v-if="tab === 'market'" />
            </div>
        </div>
        <start-paid-subscription v-model="paidSubscription" :refresh-billing="getBillingNotification" />
    </div>
</template>

<script> 
    import { defineAsyncComponent } from 'vue'
    import { mapActions, mapState } from 'vuex'

    const BillingProfile = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/Profile'))
    const SecurityComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/Security'))
    const SubscriptionComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/Subscription'))
    const PaymentMethod = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/PaymentMethod'))
    const BillingHistory = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/BillingHistory'))
    const AdditionalService = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/AdditionalService'))
    const MarketplaceComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/Marketplace'))
    const StartPaidSubscription = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/StartPaidSubscription'))

    import axios from '@/services/axios'

    export default {
        name: 'Account Billing',

        data () {
            return {
                tab: 'profile',
                paidSubscription: false,
                billingNotification: {},
                hasBillingNotificationBar: false,
                billingApi: process.env.VUE_APP_BILLING_API_URL,
            };
        },

        components: {
            BillingProfile,
            SecurityComponent,
            SubscriptionComponent,
            PaymentMethod,
            BillingHistory,
            AdditionalService,
            MarketplaceComponent,
            StartPaidSubscription
        },

        watch: {
            tab (tab) {
                const vm = this;

                vm.$router.push({ name: 'AccountBilling', query: { tab }});
            },

            user (user) {
                const vm = this;

                if (user.id) {
                    vm.getBillingNotification();
                }
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
        }),

        mounted () {
            const vm = this;

            document.body.classList.add('modal-open');

            vm.getPlans();

            if (vm.user.id) {
                vm.getBillingNotification();
            }

            if (vm.$route.query && vm.$route.query.tab) {
                vm.tab = vm.$route.query.tab;
            }
        },

        beforeUnmount () {
            document.body.classList.remove('modal-open');
        },

        methods: {
            ...mapActions({
                getPlans: 'billingModule/getPlans',
            }),

            closeModal () {
                const vm = this;

                vm.$router.push({ name: 'AdminSettings' });
                document.body.classList.remove('modal-open');
            },
            toggleSectionbar(){
                const ls_bar = document.querySelector('.global_setting');
                if(ls_bar.classList.contains('show_m_sidebar')){
                    ls_bar.classList.remove('show_m_sidebar');
                }else{
                    ls_bar.classList.add('show_m_sidebar');
                }
            },

            togglePaidSubscription () {
                const vm = this;

                vm.paidSubscription = true;
            },

            billingNotificationClose() {

                const vm = this;
                vm.hasBillingNotificationBar = false
            },

            getBillingNotification () {
                const vm = this;

                axios.get(`${vm.billingApi}/stripe/billing-notifications`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {
                    vm.billingNotification = resp.data;

                    if (resp.data.has_stripe_subscription && resp.data.has_trial) {
                        vm.hasBillingNotificationBar = true;
                    } else {
                        vm.hasBillingNotificationBar = false;
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
    };
</script>

<style scoped>

     :deep.tabs_content {
        padding-top: 30px;
    }

    :deep.header-main-sec {
        left: 270px;
    }

    :deep(.forms-setting-form) {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    :deep(.section_header h2 span) {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        display: block;
        padding-top: 10px;
    }

    :deep(.billing .setting_wpr) {
        max-width: 600px;
    }

    /* :deep(.billing .setting_wpr .group_item .input_label) {
        font-weight: 500;
    } */

    :deep(.billing .form_grp h4) {
        font-size: 18px;
        line-height: 23px;
        color: #5a5a5a;
        font-weight: 300;
        margin-top: 12px;
    }

    :deep(.profile_top) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        padding: 20px 0;
    }

    :deep(.user_img) {
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;
    }

    :deep(.user_img img) {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid #eee;
    }

    :deep(.user_img button) {
        width: 20px;
        height: 20px;
        font-size: 8px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #dbdbdb;
        color: #2f7eed;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }

    :deep(.user_img .username button) {
        border: 1px solid #2f7eed;
        padding: 7px 20px;
        border-radius: 15px;
        color: #2f7eed;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
    }

    :deep(.field_wpr) {
        border: 1px solid #dfdfdf;
    }

    :deep(.field_wpr input),
    :deep(.field_wpr select),
    :deep(.field_wpr textarea) {
        border-radius: 5px;
    }

    :deep(.point_header) {
        font-size: 17px;
        line-height: 23px;
        font-weight: 400;
        color: #121525;
        margin: 30px 0 10px;
        display: flex;
        align-items: center;
    }

    :deep(.point_header i) {
        margin-right: 10px;
        color: #999;
    }

    :deep(.para) {
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
    }

    :deep(.change_box) {
        padding: 20px 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        line-height: 21px;
        font-weight: 300;
        color: #121525;
    }

    :deep(.change_box button) {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
        padding: 5px 15px;
        border: 1px solid #84b7ff;
        border-radius: 5px;
        cursor: pointer;
    }

    :deep(.billing_card) {
        background: #fff;
        border-radius: 8px;
        margin: 20px 0 30px;
    }

    :deep(.billing_card .header) {
        padding: 12px 30px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        border-bottom: 1px solid #eee;
    }

    :deep(.billing_card .card_body) {
        padding: 20px 30px;
    }

    :deep(.billing_card .card_body p) {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        margin-bottom: 20px;
    }

    :deep(.billing_card .card_body button) {
        font-size: 13px;
        line-height: 16px;
        background: #2f7eed;
        color: #fff;
        font-weight: 500;
        padding: 8px 20px;
        border-radius: 5px;
        cursor: pointer;
        margin: 10px 0 15px;
        text-transform: uppercase;
        font-family: 'Inter', sans-serif;
    }

    :deep(.billing_card .card_body button.delete_btn) {
        background: transparent;
        color: #eb1414;
        border: 1px solid #eb1414;
        transition: all 0.3s ease-in-out;
    }

    :deep(.billing_card .card_body button.delete_btn.disable) {
        cursor: no-drop;
    }

    :deep(.billing_card .card_body button.delete_btn.active) {
        background: #eb1414;
        color: #fff;
    }

    :deep(.plan_container) {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        align-items: flex-start;
    }

    :deep(.subscription_wpr) {
        width: 55%;
        min-width: 400px;
        display: flex;
        flex-direction: column;
        margin: 20px 0 0;
    }

    :deep(.subscription) {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        margin-bottom: 30px;
    }

    :deep(.subscription .header) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 30px;
        border-bottom: 1px solid #eee;
    }

    :deep(.subscription .header h3) {
        font-size: 20px;
        line-height: 27px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
    }

    :deep(.subscription .header .price),
    :deep(.addOn_card .price) {
        font-size: 22px;
        line-height: 27px;
        font-weight: 500;
        color: #121525;
        display: flex;
    }

    :deep(.subscription .header .price span.sub),
    :deep(.addOn_card .price span.sub) {
        font-size: 13px;
        line-height: 16px;
        align-self: flex-end;
        padding: 0 0 3px 3px;
    }

    :deep(.subscription .footer) {
        padding: 10px 30px;
        border-top: 1px solid #eee;
    }

    :deep(.subscription .features) {
        padding: 20px 30px;
        position: relative;
    }

    :deep(.subscription .features ul li) {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 6px 0;
        display: flex;
        flex-wrap: wrap;
    }

    :deep(.subscription .features ul li i) {
        margin-right: 10px;
        font-size: 8px;
        color: #fff;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(117, 210, 24, 0.7);
    }

    :deep(button.btn) {
        font-size: 12px;
        line-height: 15px;
        background: #2f7eed;
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
        padding: 10px 25px;
        border-radius: 20px;
        cursor: pointer;
        font-family: 'Inter', sans-serif;
    }

    :deep(.subscription .features h4) {
        font-size: 14px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin: 15px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    :deep(.subscription .features h4 i) {
        font-size: 13px;
        margin-left: 10px;
    }

    :deep(.subscription .features .more_ft) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
    }

    :deep(.subscription .features .more_ft ul) {
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    :deep(.subscription .features .more_ft ul.active) {
        max-height: 500px;
    }

    :deep(.subscription .features a) {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 10px 20px;
        background: #eee;
        border-radius: 17px;
        cursor: default;
        display: inline-block;
    }

    :deep(.subscription .header h3 .discount) {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        background: #fdca72;
        border-radius: 11px;
        color: #121525;
        padding: 4px 10px;
        margin-left: 10px;
    }

    :deep(.addOns_Wpr) {
        margin: 20px 0 40px;
        width: 30%;
        max-width: 400px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
    }

    :deep(.addOns_Wpr .upgrade_area) {
        position: sticky;
        top: 0;
        left: 0;
        background: #fafafb;
        padding: 15px 0;
        z-index: 3;
        margin: 0 -15px;
        padding: 15px;
    }

    :deep(.subscription_wpr .result_wpr table td p) {
        font-weight: 400;
    }

    :deep(.subscription_wpr .result_wpr table td:first-child),
    :deep(.subscription_wpr .result_wpr table th:first-child) {
        width: auto;
        text-align: left;
        padding: 20px 15px;
    }

    :deep(.billing_history .result_wpr table td:last-child),
    :deep(.billing_history .result_wpr table th:last-child) {
        text-align: center;
    }

    :deep(.table_title) {
        font-size: 18px;
        line-height: 25px;
        padding: 15px 20px;
        background: #fff;
        color: #121525;
        font-weight: 500;
        border-radius: 14px 14px 0 0;
        border-bottom: 1px solid #eee;
    }

    :deep(.upgrade_area) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 800px;
        margin-bottom: 25px;
    }

    :deep(.upgrade_area .price) {
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 10px;
    }

    :deep(.upgrade_area .price span.sub) {
        font-size: 13px;
        line-height: 16px;
        align-self: flex-end;
        padding: 0 0 3px 3px;
    }

    :deep(.upgrade_area h3) {
        font-size: 20px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
    }

    :deep(.plan_type) {
        display: flex;
        font-size: 14px;
        line-height: 17px;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        color: #121525;
    }

    :deep(.payment_section ){
        max-width: 800px;
        margin-top: 30px;
    }

    :deep(.paymentcard_wpr) {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 30px;
    }

    :deep(.payment_card) {
        padding: 20px;
        border-radius: 8px;
        background: #fff;
        flex: 0 1 300px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        position: relative;
    }

    :deep(.payment_card .top_col) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    :deep(.payment_card .primary_selection) {
        display: flex;
        align-items: center;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        margin-top: 5px;
    }

    :deep(.payment_card label.checkbox input[type=radio]:checked~span i) {
        transform: scale(1);
        opacity: 1;
    }

    :deep(.payment_card label.checkbox input[type=radio]:checked~.active_line) {
        border: 1px solid rgba(47, 126, 237, 0.25);
        border-radius: 8px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    :deep(.payment_card img.chip) {
        height: 30px;
        width: auto;
    }

    :deep(.payment_card .card_number) {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 3px;
        margin-bottom: 20px;
    }

    :deep(.payment_card .card_name) {
        font-size: 11px;
        line-height: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    :deep(.payment_card .card_name h6) {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    :deep(.payment_card .card_name img.type) {
        max-height: 40px;
        width: auto;
    }

    :deep(button.add_card) {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #2f7eed;
        border-radius: 5px;
        text-transform: uppercase;
        font-family: 'Inter', sans-serif;
    }

    :deep(.infocard_wpr) {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin: 30px 0;
    }

    :deep(.info_card) {
        background: #fff;
        border-radius: 8px;
        flex: 0 1 390px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    }

    :deep(.info_card .header) {
        padding: 10px 20px;
        border-bottom: 1px solid #eee;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
    }

    :deep(.info_card .info_body) {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    :deep(.info_card .info_body img) {
        height: 80px;
        width: auto;
    }

    :deep(.info_card h3) {
        font-size: 20px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
    }

    :deep(.info_card h6) {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        margin-bottom: 10px;
    }

    :deep(.info_card .price) {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
    }

    :deep(.info_card .discount) {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        background: #fff4e0;
        border-radius: 11px;
        color: #121525;
        padding: 10px;
        display: flex;
        flex-direction: column;
        flex: 0 0 130px;
        justify-content: center;
        align-items: center;
    }

    :deep(.info_card .discount label) {
        font-size: 18px;
        line-height: 25px;
        color: #2f7eed;
        font-weight: 600;
        border-bottom: 1px solid #c9c9c9;
        padding-bottom: 3px;
        margin-bottom: 5px;
        position: relative;
    }

    :deep(.info_card .discount label small) {
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
        background: #fff4e0;
        padding: 0 5px;
    }

    :deep(.info_card .discount label span) {
        font-size: 11px;
        line-height: 15px;
        color: #121525;
        font-weight: 600;
    }

    :deep(.billing_history) {
        width: 50%;
        min-width: 550px;
    }

    :deep(.billing_history .result_wpr table td) {
        font-weight: 400;
    }

    :deep(.billing_history .result_wpr table td p) {
        font-weight: 500;
    }

    :deep(.billing_history .result_wpr table td:first-child),
    :deep(.billing_history .result_wpr table th:first-child) {
        width: auto;
        text-align: left;
        padding: 20px 15px;
    }

    :deep(.billing_history .result_wpr table td .status) {
        background: rgba(117, 210, 24, 0.2);
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 4px 12px;
        border-radius: 11px;
    }

    :deep(.billing_history .result_wpr table td .status i) {
        margin-right: 4px;
        width: auto;
        font-size: 8px;
    }

    :deep(.addOn_card) {
        border-radius: 8px;
        background: #fff;
        max-width: 400px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        margin-bottom: 30px;
    }

    :deep(.addOn_card .header) {
        padding: 15px 20px;
        border-bottom: 1px solid #eee;
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    :deep(.addOn_card .header h4) {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
    }

    :deep(.addOn_card .header h4 .discount) {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        background: #fdca72;
        border-radius: 11px;
        color: #121525;
        padding: 3px 8px;
        margin-left: 10px;
    }

    :deep(.addOn_card .addOn_info) {
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    :deep(.addOn_card .header button) {
        font-size: 10px;
        line-height: 12px;
        background: #2f7eed;
        color: #fff;
        font-weight: 400;
        padding: 5px 10px;
        border-radius: 3px;
        cursor: pointer;
        text-transform: uppercase;
        font-family: 'Inter', sans-serif;
    }

    :deep(.addOn_info .extra_wpr) {
        margin-top: 20px;
    }

    :deep(.addOn_info .extra_wpr .switch_option h5) {
        font-size: 13px;
    }

    :deep(.range_slider) {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 30px 0 15px;
    }

    :deep(.range_slider.no-move) {
        pointer-events: none;
        filter: grayscale(1);
    }

    :deep(.range_slider .range_label) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        color: #5a5a5a;
        margin: 10px 0 0;
    }

    :deep(.range_slider span) {
        height: 4px;
        background: #2f7eed;
        position: absolute;
        top: 0;
        border-radius: 3px;
    }

    :deep(.range_slider span label) {
        position: absolute;
        right: 0;
        transform: translateX(50%);
        bottom: 100%;
        padding: 1px 4px;
        background: #2f7eed;
        border-radius: 3px;
        font-size: 9px;
        line-height: 13px;
        color: #fff;
        font-weight: 400;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        display: inline-block;
        margin-bottom: 12px;
    }

    :deep(.range_slider span label:after) {
        position: absolute;
        content: '';
        border-top: 4px solid #2f7eed;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    :deep(.range_slider .slider) {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background: rgba(59, 126, 241, 0.3);
        outline: none;
        margin: 0;
        position: relative;
    }

    :deep(.range_slider .slider::-webkit-slider-thumb) {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border: 1px solid #2f7eed;
        background: #2f7eed;
        cursor: pointer;
        border-radius: 50%;
        position: relative;
        z-index: 2;
    }

    :deep(.range_slider .slider::-moz-range-thumb) {
        width: 15px;
        height: 15px;
        border: 1px solid #2f7eed;
        background: #2f7eed;
        cursor: pointer;
        border-radius: 50%;
    }

    :deep(.no-portal-chat) {
        height: calc(100vh - 315px);
        background: #fff;
        border: 1px solid #eee;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }

    :deep(.no-portal-chat h2) {
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
    }

    :deep(.security .setting_wpr) {
        width: 100%;
        padding: 15px;
    }

    :deep(.security .action_wpr) {
        justify-content: center;
        margin: 20px 0 0;
    }

    :deep(.storage) {
        padding: 5px 15px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 3px;
        position: relative;
        width: 100%;
        overflow: hidden;
        margin: 10px 0;
    }

    :deep(.storage h4) {
        font-size: 10px;
        line-height: 12px;
        color: #757575;
        font-weight: 500;
        position: relative;
        z-index: 1;
    }

    :deep(.storage span) {
        background: rgba(47, 126, 237, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    :deep(span.lh-7) {
        line-height: 7px;
    }

    .preview_area {
        -webkit-animation: none;
        animation: none;
        -webkit-animation-timing-function: cubic-bezier(1.0, 0.5);
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    :deep(.modal.security) {
        margin-top: 0px;
    }
</style>
